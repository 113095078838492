import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "@auth/services/auth.service";
import Keycloak from "keycloak-js";
import { environment } from "src/environments/environment";

export const LoggedGuard = (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const authService = inject(AuthService);
    const keycloakService = inject(Keycloak);

    const isLogged = authService.isLogged();
    const targetUrl = state.url;
    if (!isLogged) keycloakService.login({
        redirectUri: environment.keycloak.redirectUrl + targetUrl,
        locale: "fr",
        scope: "openid profile email",
    });
    return isLogged;
};
