import { Component, DestroyRef, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MatIconRegistry } from "@angular/material/icon";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { DomSanitizer } from "@angular/platform-browser";
import { Router, RouterOutlet } from "@angular/router";
import { VersionService } from "@app/version.service";
import { LanguagesService } from "@shared/services/languages.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: [ "./app.component.scss" ],
    imports: [ RouterOutlet, MatDialogModule ],
    providers: [ MatSnackBarModule ]
})
export class AppComponent implements OnInit {
  private readonly router: Router = inject(Router);
  private readonly dialog = inject(MatDialog);
  private readonly matIconRegistry = inject(MatIconRegistry);
  private readonly domSanitizer = inject(DomSanitizer);
  private readonly languagesService = inject(LanguagesService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly versionService = inject(VersionService);

  constructor() {
    this.languagesService.init();
    this.addCustomMatIcons();
  }

  ngOnInit() {
    this.router.events
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.dialog.closeAll());

    document.addEventListener("askVersion", () => {
      this.versionService.logVersion();
    });
  }

  private addCustomMatIcons() {
    this.matIconRegistry.addSvgIconResolver((name, namespace) => {
      switch (namespace) {
        case "flags":
          return this.domSanitizer.bypassSecurityTrustResourceUrl(
            `assets/i18n/countries/${name}.svg`,
          );
        default:
          return this.domSanitizer.bypassSecurityTrustResourceUrl(
            `assets/icon/material/${name}.svg`,
          );
      }
    });
  }
}
