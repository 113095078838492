import { ADAPTERS } from "@models/adapters/adapter-resolver";
import { DateModel } from "@models/adapters/date-adapter";
import { Asset } from "@models/data/asset";
import { tryInstantiate } from "@models/data/type-resolver";
import { Zone } from "@models/data/zone";

export class Cuve extends Asset {
    name: string;
    coordonneesX: number | null;
    coordonneesY: number | null;
    threshold: number;
    currentVolume: number;
    creationDate: DateModel;
    lastDetection: DateModel | null;
    lastUpdate: DateModel | null;
    zone: Zone;

    constructor(data: any) {
        super(data);
        this.name = data.name;
        this.coordonneesX = data.coordonneesX;
        this.coordonneesY = data.coordonneesY;
        this.threshold = data.threshold;
        this.currentVolume = data.currentVolume;
        this.creationDate = ADAPTERS["DateModel"].parseIfPresent(data.creationDate) as DateModel;
        this.lastDetection = data.lastDetection == null ? null : ADAPTERS["DateModel"].parseIfPresent(data.lastDetection) as DateModel;
        this.lastUpdate = data.lastUpdate == null ? null : ADAPTERS["DateModel"].parseIfPresent(data.lastUpdate) as DateModel;
        this.zone = tryInstantiate(data.zone);
    }
}
