import { ADAPTERS } from "@models/adapters/adapter-resolver";
import { DateModel } from "@models/adapters/date-adapter";
import { Application } from "@models/data/application";
import { Model } from "@models/data/model";
import { tryInstantiate } from "@models/data/type-resolver";
import { AuthenticationType } from "@models/enums/authentication-type";

export class Credential extends Model {
    type: AuthenticationType;
    name: string;
    creationDate: DateModel;
    expirationDate: DateModel | null;
    application: Application;
    permissions: string[];
    lastActivity: DateModel | null;

    constructor(data: any) {
        super(data);
        this.name = data.name;
        this.type = data.type;
        this.creationDate = ADAPTERS["DateModel"].parseIfPresent(
            data.creationDate,
        ) as DateModel;
        this.expirationDate = data.expirationDate === null ? null :  ADAPTERS["DateModel"].parseIfPresent(
            data.expirationDate,
        ) as DateModel;
        this.application = tryInstantiate(data.application);
        this.permissions = data.permissions;
        this.lastActivity = data.lastActivity === null ? null :  ADAPTERS["DateModel"].parseIfPresent(
            data.lastActivity,
        ) as DateModel;
    }
}
