import { environment } from "src/environments/environment";
import {
    AutoRefreshTokenService,
    ProvideKeycloakOptions,
    UserActivityService,
    withAutoRefreshToken,
} from "keycloak-angular";

export function getKeycloakConfig(): ProvideKeycloakOptions {
    return {
        config: {
            url: environment.keycloak.url,
            realm: environment.keycloak.realm,
            clientId: environment.keycloak.clientId,
        },
        initOptions: {
            onLoad: "check-sso",
            pkceMethod: "S256",
            silentCheckSsoRedirectUri:
                `${window.location.origin + environment.baseHref  }/assets/silent-check-sso.html`,
        },
        features: [
            withAutoRefreshToken({
                sessionTimeout: 300_000, // 5mn
                onInactivityTimeout: "login",
            }),
        ],
        providers: [
            AutoRefreshTokenService,
            UserActivityService,
        ],
    };
}
