import { ADAPTERS } from "@models/adapters/adapter-resolver";
import { DateModel } from "@models/adapters/date-adapter";
import { Credential } from "@models/data/credential";
import { Model } from "@models/data/model";
import { tryInstantiateAll } from "@models/data/type-resolver";
import { ApplicationType } from "@models/enums/application-type";

export class Application extends Model {
    name: string;
    description: string;
    type: ApplicationType;
    active: boolean;
    credentials: Credential[];
    lastActivity: DateModel | null;

    constructor(data: any) {
        super(data);
        this.name = data.name;
        this.description = data.description;
        this.type = data.type;
        this.active = data.active;
        this.credentials = tryInstantiateAll(data.credentials);this.lastActivity = data.lastActivity === null ? null :  ADAPTERS["DateModel"].parseIfPresent(
            data.lastActivity,
        ) as DateModel;

    }
}
