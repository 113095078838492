import { AdR } from "@models/data/adr";
import { Alerte } from "@models/data/alerte";
import { Alley } from "@models/data/alley";
import { AlleyColumn } from "@models/data/alley-column";
import { Application } from "@models/data/application";
import { Asset } from "@models/data/asset";
import { Atelier } from "@models/data/atelier";
import { AtelierProfil } from "@models/data/atelier-profil";
import { Batiment } from "@models/data/batiment";
import { BusinessStation } from "@models/data/businessstation";
import { Chantier } from "@models/data/chantier";
import { Credential } from "@models/data/credential";
import { Cuve } from "@models/data/cuve";
import { Dechet } from "@models/data/dechet";
import { DetectionEvent } from "@models/data/detectionevent";
import { Driver } from "@models/data/driver";
import { Flow } from "@models/data/flow";
import { GroupeLotsTechnique } from "@models/data/groupe-lots-technique";
import { Historique } from "@models/data/historique";
import { Implementation } from "@models/data/implementation";
import { Infrastructure } from "@models/data/infrastructure";
import { Inventaire } from "@models/data/inventaire";
import { InventaireEntry } from "@models/data/inventaire-entry";
import { LotTechnique } from "@models/data/lot-technique";
import { Manager } from "@models/data/manager";
import { Model } from "@models/data/model";
import { Mouvement } from "@models/data/mouvement";
import { ObjectReference } from "@models/data/objectreference";
import { Permission } from "@models/data/permission";
import { Phrases } from "@models/data/phrases";
import { Producteur } from "@models/data/producteur";
import { ReachableSchema } from "@models/data/reachableschema";
import { Role } from "@models/data/role";
import { Site } from "@models/data/site";
import { SiteTraitement } from "@models/data/site-traitement";
import { Station } from "@models/data/station";
import { Tag } from "@models/data/tag";
import { TagDraginoLds12 } from "@models/data/tag-dragino-lds-12";
import { TagMoko } from "@models/data/tag-moko";
import { TypeZone } from "@models/data/type-zone";
import { User } from "@models/data/user";
import { Zone } from "@models/data/zone";
import { instantiate } from "@shared/utils/object.utils";
import { toCamelCase } from "@shared/utils/string.utils";

export const TYPES: { [name: string]: new (data: any) => any } = {
    permission: Permission,
    objectReference: ObjectReference,
    site: Site,
    manager: Manager,
    station: Station,
    infrastructure: Infrastructure,
    implementation: Implementation,
    reachableSchema: ReachableSchema,
    zone: Zone,
    driver: Driver,
    batiment: Batiment,
    flow: Flow,
    typeZone: TypeZone,
    tag: Tag,
    groupeLotsTechnique: GroupeLotsTechnique,
    businessStation: BusinessStation,
    asset: Asset,
    detectionEvent: DetectionEvent,
    producteur: Producteur,
    lotTechnique: LotTechnique,
    chantier: Chantier,
    dechet: Dechet,
    adR: AdR,
    phrases: Phrases,
    atelier: Atelier,
    alerte: Alerte,
    user: User,
    historique: Historique,
    role: Role,
    mouvement: Mouvement,
    tagMoko: TagMoko,
    siteTraitement: SiteTraitement,
    inventaireEntry: InventaireEntry,
    atelierProfil: AtelierProfil,
    alley: Alley,
    alleyColumn: AlleyColumn,
    tagDraginoLds12: TagDraginoLds12,
    inventaire: Inventaire,
    cuve: Cuve,
    credential: Credential,
    application: Application,
};

export const getType = (key: string) => {
    if (key in TYPES) return (TYPES as Record<string, new (data: any) => any>)[key];
    throw new Error(`Missing ${key} inside type-resolver.ts`);
};

export const tryInstantiate = <T extends object>(
    args?: Partial<Model> | null,
): T => {
    if (args?.__typename) {
        const constructor = getType(toCamelCase(args.__typename));
        return instantiate(constructor, args);
    }
    return undefined as unknown as T;
};

export const tryInstantiateAll = <T extends object>(args: any[]): T[] => {
    if (args == null) return args as unknown as T[];
    return args.map((arg) => tryInstantiate<T>(arg));
};
