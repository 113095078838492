import { Alerte } from "@models/data/alerte";
import { Batiment } from "@models/data/batiment";
import { BusinessStation } from "@models/data/businessstation";
import { GroupeLotsTechnique } from "@models/data/groupe-lots-technique";
import { Historique } from "@models/data/historique";
import { Inventaire } from "@models/data/inventaire";
import { Mouvement } from "@models/data/mouvement";
import { tryInstantiate, tryInstantiateAll } from "@models/data/type-resolver";
import { TypeZone } from "@models/data/type-zone";

export class Zone extends BusinessStation {
  nom: string;
  seuilTonnage: number;
  lieAInventaire: Inventaire;
  disposeBatiment: Batiment;
  estDansGroupeLotsTechniques: GroupeLotsTechnique[];
  apparientAHistoriques: Historique[];
  estLieeATypeZone: TypeZone;
  estTraitementFinalGroupeLotsTechniques: GroupeLotsTechnique[];
  provientMouvements: Mouvement[];
  stockerDansMouvements: Mouvement[];
  coordonneesX: number;
  coordonneesY: number;
  contientAlertes: Alerte[];
  currentTonnage: number;

  constructor(data: any) {
    super(data);
    this.nom = data.nom;
    this.seuilTonnage = data.seuilTonnage;
    this.lieAInventaire = tryInstantiate(data.lieAInventaire);
    this.disposeBatiment = tryInstantiate(data.disposeBatiment);
    this.estDansGroupeLotsTechniques = tryInstantiateAll(
      data.estDansGroupeLotsTechniques,
    );
    this.apparientAHistoriques = tryInstantiateAll(data.apparientAHistoriques);
    this.estLieeATypeZone = tryInstantiate(data.estLieeATypeZone);
    this.estTraitementFinalGroupeLotsTechniques = tryInstantiateAll(
      data.estTraitementFinalGroupeLotsTechniques,
    );
    this.provientMouvements = tryInstantiateAll(data.provientMouvements);
    this.stockerDansMouvements = tryInstantiateAll(data.stockerDansMouvements);
    this.coordonneesX = data.coordonneesX;
    this.coordonneesY = data.coordonneesY;
    this.contientAlertes = tryInstantiateAll(data.contientAlertes);
    this.currentTonnage = data.currentTonnage;
  }
}
